import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Prerequisites`}</h2>
    <ul>
      <li parentName="ul">{`A Manager with access to Engagement Studio.`}</li>
    </ul>
    <h2>{`Steps`}</h2>
    <ol>
      <li parentName="ol">{`On the side bar of Engagement Studio, click `}<em parentName="li">{`AdCenter`}</em>{`.`}</li>
      <li parentName="ol">{`Click `}<em parentName="li">{`Workflows`}</em>{` on the menu.`}</li>
      <li parentName="ol">{`Click `}<em parentName="li">{`Add`}</em>{` to open a new Workflow.`}
        <ul parentName="li">
          <li parentName="ul"><em parentName="li">{`Name`}</em>{`: It is required  and it is free text but you should give a meaningful name.`}</li>
          <li parentName="ul"><em parentName="li">{`Workflow scope`}</em>{`: Select `}<em parentName="li">{`Visitor`}</em>{` or `}<em parentName="li">{`Session`}</em>{` or `}<em parentName="li">{`Outbound`}</em>{` or `}<em parentName="li">{`Outbound campaign`}</em>{` or `}<em parentName="li">{`Voice contact`}</em>{`. This field defines the type of workflow `}<em parentName="li">{`Visitor`}</em>{` is default.`}</li>
          <li parentName="ul"><em parentName="li">{`Associated channels`}</em>{`: Select `}<em parentName="li">{`Default`}</em>{` or `}<em parentName="li">{`All channels`}</em>{` or other existing. `}<em parentName="li">{`All channels`}</em>{` is defualt.`}</li>
          <li parentName="ul"><em parentName="li">{`System Workflow`}</em>{`: Select from `}<em parentName="li">{`No`}</em>{` or `}<em parentName="li">{`Yes`}</em>{`. `}<em parentName="li">{`No`}</em>{` is default.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Fill in the name and select a `}<em parentName="li">{`Workflow scope`}</em>{`.`}</li>
      <li parentName="ol">{`The canvas is displayed showing Events, Actions and Condition, according with the `}<em parentName="li">{`Workflow scope`}</em>{` selected .
`}<img parentName="li" {...{
          "src": ".../../../images/enablement/canvas.png",
          "alt": "canvas"
        }}></img></li>
      <li parentName="ol">{`Drag and drop the elements to create the workflow.`}</li>
      <li parentName="ol">{`Click the element to open the properties and click `}<em parentName="li">{`Edit element`}</em>{`.
`}<span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "0",
            "marginRight": "0",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/2fbd578abab4af3c421dcbc3c2676bf1/bc1b0/edit-element.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "40.666666666666664%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAABcElEQVQoz42OTY/SQByH+/n4CNzWE2du3Dm4Ny5A4sX1YhrZuJ/ARUM8mTW+JGqi7C5tKUzpuDPtTGuh8JgWdmOUg5M8+c3L7/9knFdfBdPIEKicha74xTLZU52XyV+k1X3GLNZcz5e8/iE5uxL0xt958naKMw1jTJpgTUpmDTZNSLTCJJoiz8itIbeWPLP7tPbQS+uOvNMEQnI7F3U6325DxHzOYimQUuJ5Htc3N8xmM8Jwgef7ezyvxvf9AwFBECBl/PCBO6VwvoQRQv8kEoIPnz4TrWK0UsRSIlYxxqQkSYJSqiZN0we01kwmE15eXOC6Lu+u3uN8jFaEZcabyzGPT08xxtaCKIrIi4Jja7fb1VmWJd1ul2azSaPR4OnZM5xIZ0fL9/vtdvsPlah6W6/X9Pt9Op0OrVaLF+fnOKGy1SibzaYuHBMc417Y6/Vot9s8OjnhueviLA7C8j9FfwqLomA0GjEcDhkMBlyOx/wGcVFMwl1kh2MAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/2fbd578abab4af3c421dcbc3c2676bf1/e93cc/edit-element.webp 300w", "/static/2fbd578abab4af3c421dcbc3c2676bf1/b0544/edit-element.webp 600w", "/static/2fbd578abab4af3c421dcbc3c2676bf1/68fc1/edit-element.webp 1200w", "/static/2fbd578abab4af3c421dcbc3c2676bf1/a2303/edit-element.webp 1800w", "/static/2fbd578abab4af3c421dcbc3c2676bf1/4293a/edit-element.webp 2400w", "/static/2fbd578abab4af3c421dcbc3c2676bf1/e6e7c/edit-element.webp 2784w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/2fbd578abab4af3c421dcbc3c2676bf1/eed55/edit-element.png 300w", "/static/2fbd578abab4af3c421dcbc3c2676bf1/7491f/edit-element.png 600w", "/static/2fbd578abab4af3c421dcbc3c2676bf1/8537d/edit-element.png 1200w", "/static/2fbd578abab4af3c421dcbc3c2676bf1/d2cc9/edit-element.png 1800w", "/static/2fbd578abab4af3c421dcbc3c2676bf1/00711/edit-element.png 2400w", "/static/2fbd578abab4af3c421dcbc3c2676bf1/bc1b0/edit-element.png 2784w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/2fbd578abab4af3c421dcbc3c2676bf1/8537d/edit-element.png",
                "alt": "edit-element",
                "title": "edit-element",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></li>
    </ol>
    <p>{`![`}</p>
    <blockquote>
      <p parentName="blockquote">{`Note: Depending on the element chosen, the properties change.`}</p>
    </blockquote>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`Click  `}<em parentName="li">{`Save`}</em>{` and click the play button to `}<em parentName="li">{`Activate`}</em>{`.`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote">{`If you run a workflow without `}<strong parentName="p">{`saving`}</strong>{` it, it will run the last one saved and not the last one cerated/edited, so you may be able to run it empty.`}</p>
    </blockquote>
    <h2>{`Result`}</h2>
    <p>{`After activating, the workflow is up and running.`}</p>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`Stop`}</em>{` is available to stop the workflow. This functionality allows you to open or close the workflow for new entries. `}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`In case of `}<em parentName="p">{`voice contact`}</em>{` workflow scope, there is the option to send or not send the end of contact event when the workflows comes to an end. `}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`In the `}<em parentName="p">{`outbound`}</em>{` workflow scope, `}<em parentName="p">{`contact list(s)`}</em>{` associated with the workflow must be selected. `}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      